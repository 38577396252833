
import React from 'react';
import {useTranslation} from "react-i18next";

const Footer = () =>{
	const {t, i18n} = useTranslation('common');
    return (
        <footer className="bg-pattern darkblue ptb-100">
			<div className="container">
				<div className="footer"></div>
				<div className="copyright">
					<div className="row">
						<div className="col-lg-12">
							<p>© 2021 Nani Studio {t('footer.arr')}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
    )
}


export default Footer;
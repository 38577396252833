
import React from 'react';
import {useTranslation} from "react-i18next";
import aboutImg from "../images/about.svg";
import ScrollAnimation from 'react-animate-on-scroll';

const Header = () =>{

	const {t, i18n} = useTranslation('common');

    return (
        <section className="about-part darkblue ptb-100">
			<div className="container">
			<ScrollAnimation animateIn='fadeIn'>
				<div className="row">
					<div className="col-md-6 wow fadeInLeft">
						<div className="about-img">
							<img src={aboutImg} alt="about" />
						</div>
					</div>
					<div className="col-md-6 flex-align wow fadeInRight">
						<div className="about-content">
							<div className="section-heading">
								<h2 className="heading-title-2 pb-20">{t('welcome.title')}</h2>
								<h3 className="sub-heading-2 pb-15">{t('welcome.title2')}</h3>
								<p>{t('welcome.desc')}</p>
							</div>
						</div>
					</div>
				</div>
				</ScrollAnimation>
			</div>
		</section>
    )
}


export default Header;
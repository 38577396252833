import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react';

import Header from './components/Header'
import Hero from './components/Hero'
import About from './components/About'
import Features from './components/Features'
import Roadmap from './components/Roadmap'
import TokenSale from './components/TokenSale'
import FAQ from './components/FAQ'
import Footer from './components/Footer'

function App() {

  return (
    <div className="App" >
      <Header />
      <Hero />
      <About />
      <Features/>
      <Roadmap />
      <FAQ />
      <Footer/>
    </div>
  );
}

export default App;
 

import React, { useState } from 'react';
import {useTranslation} from "react-i18next";



const FAQ = () =>{

	const [openQ, setOpenQ] = useState(0);

	const {t, i18n} = useTranslation('common');
    return (
        <section className="faq-part-2 skyblue ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading text-center pb-65">
							<h2 className="heading-title-2 pb-20">{t('faq.title')}</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 wow fadeInUp">
						<div className="tab-content" id="myTabContent">
						  	<div className="tab-pane fade show active" id="general" role="tabpanel">
						  		<div className="row">
						  			<div className="col-lg-6 col-md-12">
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(1)}} className="accordion-faq-title">{t('faq.f1')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 1? " show": "")}>
						  						<p>{t('faq.q1')}</p>
						  					</div>
						  				</div>
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(2)}} className="accordion-faq-title">{t('faq.f2')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 2? " show": "")}>
						  						<p>{t('faq.q2')}</p>
						  					</div>
						  				</div>
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(3)}} className="accordion-faq-title">{t('faq.f3')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 3? " show": "")}>
						  						<p>{t('faq.q3')}</p>
						  					</div>
						  				</div>
						  			</div>
						  			<div className="col-lg-6 col-md-12">
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(4)}} className="accordion-faq-title">{t('faq.f4')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 4? " show": "")}>
						  						<p>{t('faq.q4')}</p>
						  					</div>
						  				</div>
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(5)}} className="accordion-faq-title">{t('faq.f5')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 5? " show": "")}>
						  						<p>{t('faq.q5')}</p>
						  					</div>
						  				</div>
						  				<div className="accordion-faq-box mb-20">
						  					<a onClick={()=>{setOpenQ(6)}}className="accordion-faq-title">{t('faq.f6')}</a>
						  					<div className={'accordion-faq-content' + (openQ == 6? " show": "")}>
						  						<p>{t('faq.q6')}</p>
						  					</div>
						  				</div>
						  			</div>
						  		</div>
						  	</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}


export default FAQ;

import React from 'react';
import {useTranslation} from "react-i18next";
import f1 from "../images/f1.png";
import f2 from "../images/f2.png";
import f3 from "../images/f3.png";
import f4 from "../images/f4.png";

const Features = () =>{

	const {t, i18n} = useTranslation('common');

    return (
        <section className="feature-part-2 skyblue pt-100">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading flex-align pb-40">
							<h2 className="heading-title-2 wow fadeInUp white-space pr-75"><span>Best</span> Features</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-6 col-md-6 wow fadeInUp mb-30">
						<div className="feature-box-2">
							<div className="frontend">
								<div className="feature-icon-2">
									<img src={f1} alt="Safe & Secure" />
								</div>
								<div className="feature-content">
									<h3 className="feature-title-2">{t('feature.f1t')}</h3>
									<p className="feature-des">{t('feature.f1d')}</p>
								</div>
								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 wow fadeInUp mb-30">
						<div className="feature-box-2">
							<div className="frontend">
								<div className="feature-icon-2">
									<img src={f2} alt="Early Bonus" />
								</div>
								<div className="feature-content">
									<h3 className="feature-title-2">{t('feature.f2t')}</h3>
									<p className="feature-des">{t('feature.f2d')}</p>
								</div>
								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 wow fadeInUp mb-30">
						<div className="feature-box-2">
							<div className="frontend">
								<div className="feature-icon-2">
									<img src={f3} alt="Univarsal Access" />
								</div>
								<div className="feature-content">
									<h3 className="feature-title-2">{t('feature.f3t')}</h3>
									<p className="feature-des">{t('feature.f3d')}</p>
								</div>
								<div className="clear"></div>
							</div>
						</div>
					</div>
					<div className="col-lg-6 col-md-6 wow fadeInUp mb-30">
						<div className="feature-box-2">
							<div className="frontend">
								<div className="feature-icon-2">
									<img src={f4} alt="Secure Storage" />
								</div>
								<div className="feature-content">
									<h3 className="feature-title-2">{t('feature.f4t')}</h3>
									<p className="feature-des">{t('feature.f4d')}</p>
								</div>
								<div className="clear"></div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>
    )
}


export default Features;
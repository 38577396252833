import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './style/animations.css'
import './style/bootstrap.min.css'
import './style/color.css'
import './style/jquery.listtopie.css'
import './style/owl.carousel.css'
import './style/style.css'
import './style/responsive.css'
import App from './App';
import reportWebVitals from './reportWebVitals';


import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_zh from "./translations/zh/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
    interpolation: { escapeValue: false },  // React already does escaping
    lng: 'en',                              // language to use
    resources: {
        en: {
            common: common_en               // 'common' is our custom namespace
        },
        zh: {
            common: common_zh
        },
    },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
            <App/>
        </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

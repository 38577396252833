
import React, { useState, useEffect } from 'react';
import {useTranslation} from "react-i18next";
import logo from "../images/logo.svg"


const Header = () =>{

	const {t, i18n} = useTranslation('common');

    return (
        <header className="transition fixed">
			<div className="container">
				<div className="row flex-align">
					<div className="col-lg-4 col-md-3 col-8">
						<div className="logo">
							<a href=""><img src={logo} className="transition" alt="Cryptcon" /></a>
						</div>
					</div>
					<div className="col-lg-8 col-md-9 col-4 text-right">
						<div className="menu-mobile">
								<button className="btn outline btn-2" onClick={() => i18n.changeLanguage('zh')}>繁</button>
						
								<button className="btn outline btn-2" onClick={() => i18n.changeLanguage('en')}>En</button>
						
						</div>
						<div className="menu">
							<div className="signin d-inline-block">
								<button className="btn outline btn-2" onClick={() => i18n.changeLanguage('zh')}>繁體中文</button>
							</div>
							<div className="signin d-inline-block">
								<button className="btn outline btn-2" onClick={() => i18n.changeLanguage('en')}>Eng</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
    )
}


export default Header;

import React from 'react';
import {useTranslation} from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import {RadialChart} from 'react-vis';

{/* <div data-lcolor="#f8c04e">12.2</div>
<div data-lcolor="#ac56f7">32.6</div>
<div data-lcolor="#61f89f">38.2</div> */}

const myData = [
	{angle: 60, className:"g-red"},
	{angle: 20, className:"g-blue"},
	{angle: 20, className:"g-yellow"}]

const Hero = () =>{
	const {t, i18n} = useTranslation('common');
    return (
        <section className="home-banner-2 skyblue flex-align" id="particle-canvas">
							<ScrollAnimation animateIn='fadeIn'>
			<div className="container">
				<div className="row position-r z-index-2">

					<div className="col-md-6 flex-align wow fadeInLeft">
						<div className="banner-tow-content">
							<h1 className="banner-heading-tow">{t('home.title')}</h1>
							<h1 className="banner-heading-tow">{t('home.title2')}</h1>
							<p className="banner-des">{t('home.desc')}</p>
						</div>
					</div>

						<div className="col-md-6 wow fadeInRight">
							<div className="coin-counter">
								<label className="coin-start pb-20">{t('tokenSale.title')}</label>
								<div className="token-graphic-detail wow fadeInRight">
									<div className="justify-center">
									<RadialChart
										data={myData}
										width={250}
										height={250} 
										innerRadius={48} radius={100} padAngle={0.1}/>
									</div>
								<ul className="pbl">
									<li className="color-code-1">{t('tokenSale.d1')}</li>
									<li className="color-code-2">{t('tokenSale.d2')}</li>
									<li className="color-code-3">{t('tokenSale.d3')}</li>
								</ul>
						</div>
						{/* <a className="btn btn-2">{t('home.coming')}</a> */}
								<a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xbD6D62BcEB6C2c705b4416fD1A5f27aC1dbe0e17" className="btn btn-2">{t('home.buy')}</a>
							</div>
						</div>
				</div>
			</div>
			</ScrollAnimation>
		</section>
    )
}


export default Hero;
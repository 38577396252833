
import React from 'react';
import {useTranslation} from "react-i18next";

const Roadmap = () =>{
	
	const {t, i18n} = useTranslation('common');

    return (
        <section className="roadmap-part darkblue ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div className="section-heading text-center">
							<h2 className="heading-title-2 wow fadeInUp pb-20">{t('roadMap.headline')}</h2>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<div className="main-roadmap">
							<div className="row">
								<div className="col-md-12">
									<div className="h-border wow fadeInLeft"></div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="roadmap-slider">
										<div className="roadmap wow fadeInLeft">
											<div className="roadmap-box text-center">
												<div className="map-graphic">
													<div className="small-round"><span></span></div>
													<div className="v-row"></div>
												</div>
												<div className="roadmap-detail-box">
													<div className="date-title">{t('roadMap.s1t')}</div>
													<p className="roadmap-des">{t('roadMap.s1d')}</p>
												</div>
											</div>
										</div>
										<div className="roadmap wow fadeInLeft">
											<div className="roadmap-box text-center">
												<div className="map-graphic">
													<div className="small-round"><span></span></div>
													<div className="v-row"></div>
												</div>
												<div className="roadmap-detail-box">
													<div className="date-title">{t('roadMap.s2t')}</div>
													<p className="roadmap-des">{t('roadMap.s2d')}</p>
												</div>
											</div>
										</div>
										<div className="roadmap wow fadeInLeft">
											<div className="roadmap-box text-center">
												<div className="map-graphic">
													<div className="small-round"><span></span></div>
													<div className="v-row"></div>
												</div>
												<div className="roadmap-detail-box">
													<div className="date-title">{t('roadMap.s3t')}</div>
													<p className="roadmap-des">{t('roadMap.s3d')}</p>
												</div>
											</div>
										</div>
										<div className="roadmap wow fadeInLeft">
											<div className="roadmap-box text-center">
												<div className="map-graphic">
													<div className="small-round"><span></span></div>
													<div className="v-row"></div>
												</div>
												<div className="roadmap-detail-box">
													<div className="date-title">{t('roadMap.s4t')}</div>
													<p className="roadmap-des">{t('roadMap.s4d')}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    )
}


export default Roadmap;